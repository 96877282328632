import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "879px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "100%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeRIJgLgXQH/xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAEFAh//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAZEAADAQEBAAAAAAAAAAAAAAAAARFhMXH/2gAIAQEAAT8haUJpB+jy6UaVOiR//9oADAMBAAIAAwAAABBgID7/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBjP//EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAECAQE/EGP/xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMUFxsf/aAAgBAQABPxDpNP7AVwDB1xhy2BaNTXjIh78zJclZVw0efIDarP/Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Viscous Album Cover",
        "title": "Viscous Album Cover",
        "src": "/static/887ef63e3db3fdbec6dd2fa667065995/fd440/viscous-album-cover.jpg",
        "srcSet": ["/static/887ef63e3db3fdbec6dd2fa667065995/69549/viscous-album-cover.jpg 288w", "/static/887ef63e3db3fdbec6dd2fa667065995/473e3/viscous-album-cover.jpg 576w", "/static/887ef63e3db3fdbec6dd2fa667065995/fd440/viscous-album-cover.jpg 879w"],
        "sizes": "(max-width: 879px) 100vw, 879px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <PageDescription mdxType="PageDescription">
      <p>{`Release date: `}<strong>{`December 15, 2021`}</strong></p>
    </PageDescription>
    <p>{`“Viscous” is the band’s debut single (2021). Available now for purchase
on all major platforms, including but not limited to:
`}<a parentName="p" {...{
        "href": "https://music.amazon.com/albums/B09NL54VFT?ref=dm_ff_linkfire&tag=univemuisc-central-21&ie=UTF8&linkCode=as2&ascsubtag=c8bfbb5b8037b7aac8507928a87e8af0"
      }}>{`Amazon`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://music.apple.com/us/album/viscous-single/1600084402"
      }}>{`Apple`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://passengerlounge.bandcamp.com/track/viscous"
      }}>{`BandCamp`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.deezer.com/us/track/1589039992"
      }}>{`Deezer`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.pandora.com/artist/passenger-lounge/viscous-single/ALXnXxxPl9dlK72"
      }}>{`Pandora`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://stream.resonate.coop/artist/19140/release/viscous"
      }}>{`Resonate`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://soundcloud.com/passengerlounge/viscous"
      }}>{`SoundCloud`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://open.spotify.com/track/5ydIU7sbi9c89avQiVllcC?si=FxsfISfoQvumEXVykgMGLQ"
      }}>{`Spotify`}</a>{`,
`}<a parentName="p" {...{
        "href": "http://listen.tidalhifi.com/track/208962742"
      }}>{`Tidal`}</a>{`, and
`}<a parentName="p" {...{
        "href": "https://music.youtube.com/playlist?list=OLAK5uy_mZQkjDLvt-hsptFOCBvjZT7NsvHWqYqrA"
      }}>{`YouTube`}</a>{`.`}</p>
    <p>{`UPC: `}<strong>{`8720355556756`}</strong>{`
ISRC: `}<strong>{`NL4NL2184348`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      